import {
  bgPc,
  bgMobile,
  clientLogoPc,
  clientLogoMobile,
  iconGear,
  locationText,
  service,
  logoTecnovaPng,
} from "./img/index";

function App() {
  return (
    <div
      className="bg-red-200 w-screen h-screen px-[20px] py-[40px] text-white font-bold text-center flex flex-col space-y-[40px] lg:space-y-[60px] overflow-x-hidden items-center justify-center"
      style={{
        backgroundImage: `url(${bgPc})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex justify-center">
        <img src={service} alt="service-img" />
      </div>

      <div className="flex justify-center">
        <img src={logoTecnovaPng} alt="Logo" />
      </div>

      <div className="space-y-5">
        <div className="text-[20px]">
          <h1 className="font-kanit uppercase">
            our new website is currently building & will be release soon !
          </h1>
          <h1 className="font-kanit uppercase">stay tunned</h1>
        </div>

        <div className="space-y-2 font-Kanit uppercase text-[12px]">
          <p>At Tecnova, we understand that every business is unique.</p>
          <p>
            That’s why our services are designed to create custom solutions that
            perfectly align with your specific goals.
          </p>
        </div>
      </div>

      {/* <div className="flex lg:hidden justify-center">
        <img className=src={iconGear} alt="Logo" />
      </div> */}

      <div className="lg:flex hidden">
        <div className="text-white font-bold lg:flex space-x-5">
          <p>WE CREATE.</p>
          <p>WE INNOVATE.</p>
          <p>WE OPTIMISE.</p>
        </div>
      </div>

      <div className="flex justify-center">
        <img src={locationText} alt="Logo" />
      </div>

      <div className="flex lg:hidden justify-center max-w-[300px]">
        <img src={clientLogoMobile} alt="Logo" />
      </div>

      <div className="hidden lg:flex justify-center">
        <img src={clientLogoPc} alt="Logo" />
      </div>
    </div>
  );
}

export default App;
